$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 32px;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: 0;
  }

  @media screen and (min-width: $breakpoint_xl) {
    align-items: flex-end;
  }
}

.enrollButton {
  width: 100%;
  margin-bottom: 12px;

  @media screen and (min-width: $breakpoint_ml) {
    width: 276px;
    margin-bottom: 16px;
  }
}

.whyJoinLink {
  color: $white;
  opacity: 0.7;

  @include font_size(14px);
}
