$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  background-color: $black;
  min-height: 563px;
  height: 100%;
  width: 100vw;
  position: relative;

  @media screen and (min-width: $breakpoint_l) {
    min-height: 720px;
  }
}

.wrapper {
  min-height: 563px;
  height: 100%;
  position: relative;

  @media screen and (min-width: $breakpoint_l) {
    min-height: 720px;
  }
}

.content {
  width: 100%;
  min-height: 563px;
  height: 100%;
  background-color: transparent;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  @media screen and (min-width: $breakpoint_l) {
    min-height: 720px;
  }
}

.title {
  margin-bottom: 16px;
  color: $white;
  text-shadow: 0px 2px 4px #000000;

  @include text-5xl;

  @media screen and (min-width: $breakpoint_ml) {
    @include text-5xl;
  }

  @media screen and (min-width: $breakpoint_l) {
    @include text-7xl;
  }

  @media screen and (min-width: $breakpoint_xl) {
    @include text-7xl;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    @include text-7xl;
  }

  & > p > strong {
    color: $harvest-gold;
    font-style: normal;
  }
}

.link {
  @include text-xl;

  color: $harvest-gold;
  text-transform: unset;
  text-decoration: underline;
  text-shadow: 0 2px 4px #000000;
}

.mediaWrapper {
  width: 100%;
  position: absolute;
  min-height: 563px;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  @media screen and (min-width: $breakpoint_l) {
    min-height: 720px;
  }
}

.image {
  width: 100%;
  height: 100%;
}

.video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 9999;
}

.videoControls {
  position: absolute;
  visibility: hidden;
}

.videoControlButton {
  display: flex;
  color: $white;

  @extend %button-reset;

  &:hover {
    opacity: 0.8;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  > svg {
    font-size: 32px;
  }
}

.searchBarWrapper {
  padding: 20px 0;
  z-index: 10;
}
