$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  display: block;
  margin-bottom: 8px;
  color: $dove-gray;

  @include text-xs-medium-uppercase;
}

.errorLabel {
  display: block;
  margin-top: 8px;
  color: $maximum-red;

  @include text-sm-regular;
}

.noticeLabel {
  display: block;
  margin-top: 8px;
  color: $dove-gray;

  @include text-sm-regular;
}
