$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  display: none;

  @media screen and (min-width: $breakpoint_l) {
    flex-shrink: 0;
    position: sticky;
    top: 0;
    display: block;
    height: 100vh;
    padding-bottom: 60px;
    width: 220px;
    background-color: $white;
    z-index: 4;
    overflow: hidden;
  }
}

.content {
  margin-top: 20px;
  position: relative;
  top: $desktop_header_height;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
