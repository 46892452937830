$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.footer {
  background-color: $black;
  z-index: 2;
}

.mainWrapper {
  padding: $mobile_section_vertical_gutter 0;

  @media screen and (min-width: $breakpoint_ml) {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: $breakpoint_l) {
    padding: $desktop_section_vertical_gutter 0;
  }
}

.logo {
  margin-bottom: 17px;
  width: 34px;
  height: auto;
  cursor: pointer;
}

.contentWrapper {
  @media screen and (min-width: $breakpoint_l) {
    display: flex;
  }
}

.logoWrapper {
  margin: 0 0 32px 0;

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 65px 0 0;
  }
}

.nav {
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 120px));
  flex-wrap: wrap;
  gap: 32px;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 41px;
    display: flex;
    gap: 32px 64px;
  }

  .link {
    display: block;
    color: $white;
    text-transform: uppercase;

    @include font_size(12px);

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &:hover,
    &:focus,
    &:active {
      color: $hover-color;
    }
  }
}

.contentButtons {
  @media screen and (min-width: $breakpoint_l) {
    display: flex;
    align-items: center;
  }
}

.make100Button {
  color: $harvest-gold;
  text-transform: uppercase;
  display: block;
  margin: 32px 0 0;

  @include font_size(12px);

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 0 0 32px;
  }
}

.additionalWrapper {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.privacyLink,
.copyright {
  color: $white;
  opacity: 0.7;

  @include font_size(12px);
}

.privacyLink {
  &:first-child {
    margin-right: 12px;

    @media screen and (min-width: $breakpoint_l) {
      margin-right: 24px;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $hover-color;
  }
}

.copyright {
  text-transform: uppercase;
}

.divider {
  margin: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
}
