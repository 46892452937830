$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  display: flex;
  align-self: center;
  justify-content: flex-start;
  margin: auto;
  width: 360px;
  position: relative;
  z-index: 10;

  @media screen and (min-width: $breakpoint_m) {
    width: 600px;
  }

  @media screen and (min-width: $breakpoint_ml) {
    width: 800px;
  }
}

.searchBarSelect {
  max-width: 265px;

  @media screen and (min-width: $breakpoint_m) {
    max-width: 505px;
  }

  @media screen and (min-width: $breakpoint_ml) {
    max-width: 694px;
  }
}
