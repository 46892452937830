$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  &.carouselItem {
    width: 168px;

    @media screen and (min-width: $breakpoint_l) {
      width: 194px;
    }

    .imageWrapper {
      height: 298px;

      @media screen and (min-width: $breakpoint_l) {
        height: 345px;
      }
    }
  }

  &.storeType {
    position: relative;

    .imageWrapper {
      border: 3px solid $black;
    }

    .playIcon,
    .pauseIcon {
      font-size: 48px;
      left: 16px;
      bottom: 16px;
      top: initial;
      transform: initial;

      @media screen and (min-width: $breakpoint_l) {
        font-size: 64px;
        left: 24px;
        bottom: 24px;
      }
    }
  }

  &.listType {
    .imageWrapper {
      height: auto;
    }

    .image,
    .video {
      aspect-ratio: 164/291;
    }

    .priceLabel {
      @media screen and (min-width: $breakpoint_l) {
        @include text-lg-regular-underline;
      }
    }
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $black;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playIcon,
.pauseIcon {
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: $white;

  @media screen and (min-width: $breakpoint_l) {
    top: initial;
    transform: initial;
    left: 8px;
    bottom: 8px;
  }

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.pauseIcon {
  display: none;
}

.infoBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.athleteLink {
  margin: 12px 0 16px;
  color: $black;
  flex-grow: 1;

  @include text-sm-medium;
  @include line_clamp(1);

  @media screen and (min-width: $breakpoint_l) {
    @include text-lg-medium;
  }
}

.orderWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priceLabel {
  color: $black;

  @include text-xs-regular-underline;

  @media screen and (min-width: $breakpoint_l) {
    @include text-base-regular-underline;
  }
}

.orderLink {
  width: 100%;
  border: $border-light-grey;
  text-align: center;
  color: $black;
  padding: 8px 0;

  @include text-xs-medium-uppercase;
}
