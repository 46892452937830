$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  width: 100%;
  height: 100%;
  margin: auto;
  outline: none;
  overflow: hidden;
  background: $white;
  display: flex;
  flex-direction: column;
}

.imageWrapper {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $concrete;
  border: $border-light-grey;
  overflow: hidden;
  aspect-ratio: 1;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 12px;
  }
}

.image {
  display: block;
  margin: 0 auto;
}

.emptyImageText {
  text-align: center;
  padding: 0 20px;
  color: $dusty-grey;

  @include text-lg-regular;
}

.infoBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.storeName {
  color: $black;
  margin-bottom: 4px;

  @include text-sm-medium;
  @include line_clamp(1);

  @media screen and (min-width: $breakpoint_l) {
    @include text-lg-medium;
  }
}

.products,
.sportName {
  color: $dove-gray;
  margin-bottom: 12px;

  @include text-xs-regular;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 16px;

    @include text-base-regular;
  }
}

.products {
  display: flex;
  flex-wrap: wrap;
}

.productsItem {
  position: relative;

  &:not(:last-child) {
    margin-right: 10px;

    @media screen and (min-width: $breakpoint_l) {
      margin-right: 14px;
    }

    &::after {
      content: "";
      position: absolute;
      right: -6px;
      top: 6px;
      width: 2px;
      height: 2px;
      background-color: $dove-gray;

      @media screen and (min-width: $breakpoint_l) {
        right: -8px;
        top: 10px;
      }
    }
  }
}

.sportName {
  flex-grow: 1;
}

.viewMore {
  border: $border-light-grey;
  text-align: center;
  color: $black;
  padding: 8px 0;

  @include text-xs-medium-uppercase;
}
