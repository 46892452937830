$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_ml) {
    flex-direction: row;
  }
}

.firstImageWrapper {
  flex-shrink: 0;
  background-color: $black;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  margin: 0 auto;

  @media screen and (min-width: $breakpoint_ml) {
    width: 460px;
    height: 460px;
  }

  @media screen and (min-width: $breakpoint_l) {
    width: 520px;
    height: 520px;
  }

  @media screen and (min-width: $breakpoint_xl) {
    width: 580px;
    height: 580px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    width: 708px;
    height: 708px;
  }
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: $breakpoint_ml) {
    margin-left: 28px;
  }

  @media screen and (min-width: $breakpoint_l) {
    margin-left: 38px;
  }

  @media screen and (min-width: $breakpoint_xl) {
    margin-left: 48px;
  }
}

.firstContent {
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_ml) {
    margin-top: 0;
  }
}

.firstMeta {
  margin-bottom: 8px;
  color: $dove-gray;

  @include text-xs-medium-uppercase;

  @media screen and (min-width: $breakpoint_ml) {
    margin-bottom: 16;
  }
}

.firstMetaName {
  color: $black;

  @include text-xs-medium-uppercase-underline;
}

.firstDescription {
  margin-bottom: 24px;
  color: $black;

  @include text-base-regular;

  @media screen and (min-width: $breakpoint_ml) {
    margin-bottom: 32px;

    @include text-lg-regular;
  }
}

.firstTitle {
  margin-bottom: 16px;
  color: $black;

  @include text-3xl;

  @media screen and (min-width: $breakpoint_ml) {
    @include text-4xl;
  }

  @media screen and (min-width: $breakpoint_xl) {
    @include text-5xl;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    @include text-6xl;
  }
}

.cardList {
  margin-top: 32px;

  @media screen and (min-width: $breakpoint_ml) {
    margin-top: 42px;
  }
}

.cardListTitle {
  margin-bottom: 14px;
  color: $dove-gray;

  @include text-xs-medium-uppercase;
}

.card {
  border-top: 1px solid $platinum;
  padding: 16px 0;
  display: flex;

  &:last-child {
    padding-bottom: 0;
  }
}

.image {
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  background-color: $black;

  @media screen and (min-width: $breakpoint_ml) {
    width: 90px;
    height: 90px;
  }

  @media screen and (min-width: $breakpoint_xl) {
    width: 108px;
    height: 108px;
  }
}

.entry {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: $breakpoint_ml) {
    margin-left: 16px;
  }
}

.title {
  color: $black;
  margin-bottom: 4px;

  @include text-base-medium;

  @media screen and (min-width: $breakpoint_ml) {
    @include text-lg-medium;
  }
}

.meta {
  display: inline-block;
  color: $dove-gray;

  @include text-xs-medium-uppercase;
}

.metaName {
  @include text-xs-medium-uppercase-underline;
}

.link {
  position: relative;
  border-radius: 0;
  text-align: left;
  align-self: baseline;
  padding: 0;
  border: none;

  @include text-xs-medium-uppercase-underline;

  &.loading {
    color: transparent;
    pointer-events: none;
  }
}
