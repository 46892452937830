$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  display: grid;
  grid-gap: 32px 16px;
  max-width: unset;
  grid-template-columns: repeat(2, [col-start] 1fr);

  @media screen and (min-width: $breakpoint_ml) {
    grid-template-columns: repeat(3, [col-start] 1fr);
  }

  @media screen and (min-width: $breakpoint_l) {
    grid-gap: 48px 16px;
    grid-template-columns: repeat(2, [col-start] 1fr);
  }

  @media screen and (min-width: $breakpoint_xl) {
    grid-template-columns: repeat(3, [col-start] 1fr);
  }
}
