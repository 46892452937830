$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  margin-bottom: 66px;
  display: flex;
  flex-direction: column;

  &:first-child {
    padding-top: 40px;
  }
}

.title {
  display: block;
  margin: 0 29px;
  color: $silver-chalice;
  padding-bottom: 4px;
  color: $dove-gray;

  @include text-xs-medium-uppercase;
}

.list {
  margin-top: 20px;
}
