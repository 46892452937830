$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

$min_mobile_merch_image_height: 360px;
$min_mobile_custom_merch_image_height: 310px;
$mobile_merch_padding: calc(#{$min_mobile_merch_image_height} / 3);
$mobile_custom_merch_padding: calc(
  #{$min_mobile_custom_merch_image_height} / 2
);

.root {
  margin: 0;
  padding: $mobile_section_vertical_gutter 0;
  position: relative;
  max-width: 100vw;
  background-color: $black;
  z-index: 1;

  @media screen and (min-width: $breakpoint_l) {
    padding: $desktop_section_vertical_gutter 0;
  }

  .resolutionWrapper {
    @media screen and (min-width: $breakpoint_xl) {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &.rootMerch {
    padding-top: $mobile_merch_padding;
    margin-top: $mobile_merch_padding;

    @media screen and (min-width: $breakpoint_ml) {
      margin-top: 0;
      padding-top: $mobile_section_vertical_gutter;
    }

    @media screen and (min-width: $breakpoint_l) {
      padding-top: $desktop_section_vertical_gutter;
    }

    // change a size of image as expect more clean png without extra unnecessary space
    &.rootCustomMerch {
      padding-top: $mobile_custom_merch_padding;
      margin-top: $mobile_custom_merch_padding;

      @media screen and (min-width: $breakpoint_ml) {
        margin-top: 0;
        padding-top: $mobile_section_vertical_gutter;
      }

      @media screen and (min-width: $breakpoint_l) {
        padding-top: $desktop_section_vertical_gutter;
      }

      .image {
        height: $min_mobile_custom_merch_image_height;

        @media screen and (min-width: $breakpoint_l) {
          height: 350px;
        }
      }
    }

    .title {
      margin-top: 16px;

      @media screen and (min-width: $breakpoint_ml) {
        margin-top: 0;
      }
    }

    .imageRoot {
      width: 100%;
      height: 100%;
      pointer-events: none;

      @media screen and (min-width: $breakpoint_xl) {
        position: relative;
      }
    }

    .imageWrapper {
      width: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);

      @media screen and (min-width: $breakpoint_ml) {
        width: auto;
        top: 50%;
        right: 0;
        left: unset;
        transform: translate(0, -50%);
      }

      @media screen and (min-width: $breakpoint_xl) {
        width: 100%;
        top: 50%;
        right: unset;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .image {
      margin: 0 auto;
      position: relative;
      width: 100%;
      height: $min_mobile_merch_image_height;

      @media screen and (min-width: $breakpoint_l) {
        height: 540px;
      }
    }
  }

  &.rootEnroll {
    margin-top: 250px;

    @media screen and (min-width: $breakpoint_ml) {
      margin-top: 40px;
    }

    @media screen and (min-width: $breakpoint_l) {
      margin-top: 0;
    }

    @media screen and (min-width: $breakpoint_xxl) {
      margin-top: 40px;
    }

    .imageRoot {
      pointer-events: none;
    }

    .imageWrapper {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);

      @media screen and (min-width: $breakpoint_ml) {
        top: initial;
        left: initial;
        transform: initial;
        bottom: 0;
        right: 20px;
      }

      @media screen and (min-width: $breakpoint_xl) {
        bottom: -#{$desktop_section_vertical_gutter};
        right: 28%;
      }

      @media screen and (min-width: $breakpoint_xxl) {
        right: 26%;
      }

      .image {
        width: 278px;
        height: 252px;

        @media screen and (min-width: $breakpoint_ml) {
          width: 364px;
          height: 330px;
        }
      }
    }
  }
}

.contentWrapper {
  margin-bottom: 24px;
  max-width: 508px;
  width: 100%;
  flex-shrink: 0;

  @media screen and (min-width: $breakpoint_xl) {
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: 16px;

  @include text-4xl;

  @media screen and (min-width: $breakpoint_l) {
    @include text-5xl;
  }

  @media screen and (min-width: $breakpoint_xl) {
    @include text-6xl;
  }
}

.description {
  opacity: 0.7;

  @include text-sm-regular;

  @media screen and (min-width: $breakpoint_l) {
    @include text-base-regular;
  }
}

.linkButton {
  flex-shrink: 0;
  width: 100%;

  @media screen and (min-width: $breakpoint_ml) {
    width: 195px;
  }
}
