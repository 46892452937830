$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.listItem {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &.active {
    .label,
    .icon {
      color: $harvest-gold;
    }

    .link {
      background: $cultured;
    }
  }
}

.link {
  width: 100%;
  padding: 8px 29px 7px;
  display: flex;
  align-items: center;

  @extend %button-reset;

  &:hover {
    .icon,
    .label {
      color: $harvest-gold;
    }
  }
}

.icon {
  margin-right: 14px;
  margin-left: 2px;
  font-size: 20px;
  color: $dove-gray;
  flex-shrink: 0;
}

.arrowIcon {
  font-size: 18px;
  color: $dove-gray;
  flex-shrink: 0;
}

.label {
  margin-right: 12px;
  color: $dove-gray;
  flex-grow: 1;
  text-align: left;

  @include text-sm-medium-uppercase;
}

.subMenu {
  margin-top: 10px;
}

.subMenuListItem {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.subMenuLink {
  display: block;
  padding: 0 29px 0 65px;
  width: 100%;
  color: $dove-gray;

  @include text-base-regular;

  &:hover,
  &.active {
    color: $harvest-gold;
  }
}
