$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  position: relative;
}

.nav {
  position: absolute;
  right: 0;
  top: -30px;

  @media screen and (min-width: $breakpoint_l) {
    top: -50px;
    display: flex;
    gap: 10px;
  }
}

.navHide {
  display: none;
}

.slider {
  margin-right: -$mobile_gutter;
  width: 100%;

  @media screen and (min-width: $breakpoint_l) {
    margin-right: -$desktop_gutter;
  }

  [class*="slick-track"] {
    display: flex;
  }

  [class*="slick-slide"] {
    height: inherit;

    & > div {
      height: 100%;
      margin: 0 8px 0 0;

      @media screen and (min-width: $breakpoint_l) {
        margin: 0 16px 0 0;
      }
    }
  }
}

.root::after {
  @media screen and (min-width: $breakpoint_xxl) {
    height: 100%;
    width: 72px;
    position: absolute;
    top: 0;
    right: -100px;
    content: "";
    display: block;
    background: linear-gradient(
      270deg,
      #ffffff 27.91%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.theme-black.root::after {
  background: linear-gradient(270deg, #000000 27.91%, rgba(0, 0, 0, 0) 100%);
}

.arrowIcon {
  font-size: 25px;
  color: black;
  opacity: 1;
  border-radius: 50%;
  background-color: gainsboro;
  position: relative;
  cursor: pointer;
  margin-left: 10px;

  @media screen and (min-width: $breakpoint_m) {
    font-size: 35px;
  }
}
