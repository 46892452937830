$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  width: 100vw;
  min-height: 563px;
  height: 100%;
  position: relative;

  @media screen and (min-width: $breakpoint_l) {
    height: 720px;
  }

  & :global {
    .slick-dots {
      width: 100%;
      max-width: $max-resolution-size;
      z-index: 2;
      padding: 0 $mobile_gutter;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;

      @media screen and (min-width: $breakpoint_l) {
        bottom: 116px;
        padding: 0 $desktop_gutter;
      }
    }

    .slick-dots li {
      width: 16px;

      button {
        &:before {
          width: 8px;
          height: 8px;
          font-size: 8px;
          line-height: 8px;
        }

        &:hover {
          &:before {
            color: $white;
          }
        }
      }

      &[class*="slick-active"] button:before {
        color: $white;
      }
    }
  }
}
