$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

$desktop_main-component_top-padding: 22px;
$mobile_main-component_top-padding: 40px;
$desktop_main-component_bottom-padding: 40px;
$mobile_main-component_bottom-padding: 80px;
$desktop_header_height-padding: 40px;
$mobile_header_height-padding: 22px;
$desktop_footer_height: 308px;
$mobile_footer_height: 576px;

.main {
  position: relative;
  background-color: $dark;
  padding: $mobile_main-component_top-padding 0
    $mobile_main-component_bottom-padding;
  z-index: 3;
  overflow: visible;

  @media screen and (min-width: $breakpoint_l) {
    padding: $desktop_main-component_top-padding $desktop_gutter
      $desktop_main-component_bottom-padding;
  }

  @each $name, $value in $interface_colors {
    &.backgroundColor-#{$name} {
      background-color: $value;
    }
  }

  &.withFixedHeader {
    padding-top: $mobile_header_height-padding + $mobile_header_height;
    min-height: calc(100% - #{$mobile_footer_height});

    @media screen and (min-width: $breakpoint_l) {
      padding-top: $desktop_header_height-padding + $desktop_header_height;
      min-height: calc(100% - #{$desktop_footer_height});
    }
  }

  &.withProfileNavigation {
    display: flex;
    padding: 0;
  }

  &.withoutBottomPadding {
    padding-bottom: 0;
  }

  &.withoutSidesPadding {
    padding: 0;
    padding-top: $mobile_header_height;
    // use "withoutSidesPadding" as a flag for new redesigned pages
    font-family: "GT America";

    @media screen and (min-width: $breakpoint_l) {
      padding: 0;
      padding-top: $desktop_header_height;
    }
  }
}

.dashboardRoot {
  display: flex;
  justify-content: space-between;
  max-width: $max-resolution-size;
  margin: 0 auto;
  width: 100%;
}

.dashboardContent {
  padding: $mobile_gutter;
  padding: calc(#{$desktop_header_height} + #{$mobile_gutter}) $mobile_gutter;
  background: $white;
  flex-grow: 1;
  width: 100%;

  @media screen and (min-width: $breakpoint_l) {
    background: transparent;
    // 32px but should be 40px. keep 32 to make old pages looks fine
    padding: calc(#{$desktop_header_height} + 32px) 30px $desktop_gutter;
    width: calc(100vw - (#{$profile-nav-width} + 32px));
  }
}
