$cdn: 'https://cdn.millions.co';
@use "sass:color";
@import "styles2/scss/base";

.root {
  @include even_grid_columns(2);

  display: grid;
  grid-gap: 32px 16px;
  max-width: unset;

  @media screen and (min-width: $breakpoint_ml) {
    @include even_grid_columns(3);
  }

  @media screen and (min-width: $breakpoint_l) {
    @include even_grid_columns(2);

    grid-gap: 48px 18px;
  }

  @media screen and (min-width: $breakpoint_xl) {
    @include even_grid_columns(3);
  }

  &.ad {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media screen and (min-width: $breakpoint_m) {
      @include even_grid_columns(3);
    }

    @media screen and (min-width: $breakpoint_ml) {
      @include even_grid_columns(4);
    }

    @media screen and (min-width: $breakpoint_l) {
      @include even_grid_columns(3);

      grid-gap: 32px 16px;
    }

    @media screen and (min-width: $breakpoint_xl) {
      @include even_grid_columns(4);
    }
  }

  &.store {
    @media screen and (min-width: $breakpoint_l) {
      grid-gap: 24px 16px;
    }
  }
}

.rootCarousel {
  & a {
    width: 168px;

    @media screen and (min-width: $breakpoint_l) {
      width: 300px;
    }
  }
}
